import $, { each } from "jquery";

const ready = function() {
  // $(".js-elevator-block").click(function(event) {
  //   event.preventDefault();

  //   $(".elevator-speech").toggleClass("hidden");
  // });

  $(".js-pin").each(function(index, el) {
    if ($(el).hasClass("pin")) {
      var object = $("<p>" + $(el).html() + "</p>");
    } else {
      var object = $(
        '<h5><a class="js-scroll-link" href="' +
          $(el).data("link") +
          '">' +
          $(el).text() +
          "</a></h5>"
      );
    }

    $(".stack").append(object);
    $(object).addClass("pinned");
    $(object).attr("id", "pin" + index);
  });

  scrollMagic();
  $(window).scroll(scrollMagic);
  $(window).resize(scrollMagic);
};

function scrollMagic() {
  $(".js-pin").each(function(index, el) {
    var y = $(el).offset().top;
    //console.log(y-$(window).scrollTop());
    if (y <= $(window).scrollTop()) {
      if (index == 0) {
        $(".contact-button").addClass("hidden");
      }
      $("#pin" + index).addClass("visible");
      //$('#pin'+index).show(300);
    } else {
      if (index == 0) {
        $(".contact-button").removeClass("hidden");
      }
      $("#pin" + index).removeClass("visible");
      //$('#pin'+index).hide(100);
    }
  });

  var footerMarginTop = ($(window).height() - $(".stack").height()) / 2;

  if (
    $(window).scrollTop() >
    document.documentElement.scrollHeight - $(window).height() - 1
  ) {
    $(".stack").addClass("centered");
  } else {
    $(".stack").removeClass("centered");
  }

  $(".outtro").css("height", $(window).height() + 3 + "px");
  $(".footer-team-block").css("margin-top", footerMarginTop - 110 + "px");
}

$(document).ready(ready);
