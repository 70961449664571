import $, { each } from "jquery";

const ready = function() {
  if ($(".floating-header").length) {
    floatingHeader();
    window.addEventListener("scroll", floatingHeader);
  }
  if ($(".floating-buttons").length) {
    floatingButtons();
    window.addEventListener("scroll", floatingButtons);
  }
};
const floatingHeader = () => {
  let extraOffset = $(window).scrollTop() * 2;
  $(".floating-header").css(
    "transform",
    `translateY(${extraOffset * -0.05}px)`
  );
};
const floatingButtons = () => {
  let extraOffset = $(window).scrollTop() * 2;
  $(".floating-buttons").css(
    "transform",
    `translateY(${extraOffset * 0.05}px)`
  );
};

$(document).ready(ready);
