import $, { each } from "jquery";

const ready = function() {
  var windowHeight = window.innerHeight;
  var scrollArea = 2800 - windowHeight;
  var row1 = document.getElementsByClassName("row1")[0];
  var row2 = document.getElementsByClassName("row2")[0];
  var row3 = document.getElementsByClassName("row3")[0];

  var row1m = document.getElementsByClassName("row1-m")[0];
  var row2m = document.getElementsByClassName("row2-m")[0];
  var row3m = document.getElementsByClassName("row3-m")[0];
  var row4m = document.getElementsByClassName("row4-m")[0];
  var row5m = document.getElementsByClassName("row5-m")[0];

  if (row1) {
    window.addEventListener("scroll", function() {
      var scrollTop = window.pageYOffset || window.scrollTop;
      var scrollPercent = scrollTop / scrollArea || 0;

      row1.style.right = (scrollPercent * window.innerHeight) / 50 - 20 + "em";
      row2.style.right = (scrollPercent * window.innerHeight) / 40 - 15 + "em";
      row3.style.right = (scrollPercent * window.innerHeight) / 30 - 30 + "em";

      row1m.style.right = (scrollPercent * window.innerHeight) / 50 - 22 + "em";
      row2m.style.right = (scrollPercent * window.innerHeight) / 40 - 19 + "em";
      row3m.style.right = (scrollPercent * window.innerHeight) / 30 - 4 + "em";
      row4m.style.right = (scrollPercent * window.innerHeight) / 25 - 3 + "em";
      row5m.style.right = (scrollPercent * window.innerHeight) / 20 - 18 + "em";
    });
  }
};

$(document).ready(ready);
