import $, { each } from "jquery";

const state = {
  2011: {
    q1: {
      countries: [
        {
          id: "ru-row",
          units: 1
        }
      ],
      mapUnits: "#KO"
    },

    q2: {
      countries: [
        {
          id: "ru-row",
          units: 5
        }
      ],
      mapUnits: "#KO, #ARК"
    },

    q3: {
      countries: [
        {
          id: "ru-row",
          units: 8
        }
      ],
      mapUnits: "#KO, #ARK, #KIR"
    },

    q4: {
      countries: [
        {
          id: "ru-row",
          units: 11
        }
      ],
      mapUnits: "#KO, #ARK, #KIR"
    }
  },

  2012: {
    q1: {
      countries: [
        {
          id: "ru-row",
          units: 14
        },

        {
          id: "kz-row",
          units: 1
        }
      ],
      mapUnits: "#KO, #ARK, #KIR, #KOS, #SVE, #kz-map"
    },

    q2: {
      countries: [
        {
          id: "ru-row",
          units: 18
        },

        {
          id: "kz-row",
          units: 1
        }
      ],
      mapUnits: "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #kz-map"
    },

    q3: {
      countries: [
        {
          id: "ru-row",
          units: 20
        },

        {
          id: "kz-row",
          units: 1
        }
      ],
      mapUnits: "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #kz-map"
    },

    q4: {
      countries: [
        {
          id: "ru-row",
          units: 25
        },

        {
          id: "kz-row",
          units: 2
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #kz-map"
    }
  },

  2013: {
    q1: {
      countries: [
        {
          id: "ru-row",
          units: 28
        },

        {
          id: "kz-row",
          units: 3
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #kz-map"
    },

    q2: {
      countries: [
        {
          id: "ru-row",
          units: 30
        },

        {
          id: "kz-row",
          units: 6
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #kz-map"
    },

    q3: {
      countries: [
        {
          id: "ru-row",
          units: 32
        },

        {
          id: "kz-row",
          units: 8
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #kz-map"
    },

    q4: {
      countries: [
        {
          id: "ru-row",
          units: 36
        },

        {
          id: "kz-row",
          units: 11
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #kz-map"
    }
  },

  2014: {
    q1: {
      countries: [
        {
          id: "ru-row",
          units: 42
        },

        {
          id: "kz-row",
          units: 14
        },

        {
          id: "ro-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #kz-map, #ro-map"
    },

    q2: {
      countries: [
        {
          id: "ru-row",
          units: 62
        },

        {
          id: "kz-row",
          units: 17
        },

        {
          id: "ro-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #kz-map, #ro-map"
    },

    q3: {
      countries: [
        {
          id: "ru-row",
          units: 87
        },

        {
          id: "kz-row",
          units: 18
        },

        {
          id: "ro-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #kz-map, #ro-map"
    },

    q4: {
      countries: [
        {
          id: "ru-row",
          units: 94
        },

        {
          id: "kz-row",
          units: 21
        },

        {
          id: "ro-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, #kz-map, #ro-map"
    }
  },

  2015: {
    q1: {
      countries: [
        {
          id: "ru-row",
          units: 105
        },

        {
          id: "kz-row",
          units: 22
        },

        {
          id: "ro-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #kz-map, #ro-map, #by-map"
    },

    q2: {
      countries: [
        {
          id: "ru-row",
          units: 116
        },

        {
          id: "kz-row",
          units: 23
        },

        {
          id: "ro-row",
          units: 1
        },

        {
          id: "by-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV, #kz-map, #ro-map, #by-map"
    },

    q3: {
      countries: [
        {
          id: "ru-row",
          units: 131
        },

        {
          id: "kz-row",
          units: 23
        },

        {
          id: "ro-row",
          units: 1
        },

        {
          id: "by-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #kz-map, #ro-map, #by-map"
    },

    q4: {
      countries: [
        {
          id: "ru-row",
          units: 149
        },

        {
          id: "kz-row",
          units: 24
        },

        {
          id: "ro-row",
          units: 1
        },

        {
          id: "by-row",
          units: 2
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #kz-map, #ro-map, #by-map"
    }
  },

  2016: {
    q1: {
      countries: [
        {
          id: "ru-row",
          units: 196
        },

        {
          id: "kz-row",
          units: 27
        },

        {
          id: "ro-row",
          units: 2
        },

        {
          id: "by-row",
          units: 4
        },
        {
          id: "lt-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #kz-map, #ro-map, #by-map, #lt-map"
    },

    q2: {
      countries: [
        {
          id: "ru-row",
          units: 216
        },

        {
          id: "kz-row",
          units: 31
        },

        {
          id: "ro-row",
          units: 2
        },

        {
          id: "by-row",
          units: 7
        },
        {
          id: "lt-row",
          units: 1
        },

        {
          id: "ee-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #kz-map, #ro-map, #by-map, #lt-map, #ee-map"
    },

    q3: {
      countries: [
        {
          id: "ru-row",
          units: 278
        },

        {
          id: "kz-row",
          units: 31
        },

        {
          id: "ro-row",
          units: 2
        },

        {
          id: "by-row",
          units: 12
        },
        {
          id: "lt-row",
          units: 1
        },

        {
          id: "ee-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #kz-map, #ro-map, #by-map, #lt-map, #ee-map"
    },

    q4: {
      countries: [
        {
          id: "ru-row",
          units: 321
        },

        {
          id: "kz-row",
          units: 36
        },

        {
          id: "ro-row",
          units: 2
        },

        {
          id: "by-row",
          units: 12
        },
        {
          id: "lt-row",
          units: 2
        },

        {
          id: "ee-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #kz-map, #ro-map, #by-map, #lt-map, #ee-map"
    }
  },

  2017: {
    q1: {
      countries: [
        {
          id: "ru-row",
          units: 346
        },

        {
          id: "kz-row",
          units: 40
        },

        {
          id: "ro-row",
          units: 2
        },

        {
          id: "by-row",
          units: 12
        },
        {
          id: "lt-row",
          units: 2
        },

        {
          id: "ee-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK,  #kz-map, #ro-map, #by-map, #lt-map, #ee-map"
    },

    q2: {
      countries: [
        {
          id: "ru-row",
          units: 352
        },

        {
          id: "kz-row",
          units: 42
        },

        {
          id: "ro-row",
          units: 3
        },

        {
          id: "by-row",
          units: 14
        },
        {
          id: "lt-row",
          units: 4
        },

        {
          id: "ee-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK,  #kz-map, #ro-map, #by-map, #lt-map, #ee-map"
    },

    q3: {
      countries: [
        {
          id: "ru-row",
          units: 389
        },

        {
          id: "kz-row",
          units: 51
        },

        {
          id: "ro-row",
          units: 3
        },

        {
          id: "by-row",
          units: 18
        },
        {
          id: "lt-row",
          units: 5
        },

        {
          id: "ee-row",
          units: 3
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #kz-map, #ro-map, #by-map, #lt-map, #ee-map"
    },

    q4: {
      countries: [
        {
          id: "ru-row",
          units: 401
        },

        {
          id: "kz-row",
          units: 59
        },

        {
          id: "ro-row",
          units: 4
        },

        {
          id: "by-row",
          units: 20
        },
        {
          id: "lt-row",
          units: 6
        },

        {
          id: "ee-row",
          units: 6
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map"
    }
  },

  2018: {
    q1: {
      countries: [
        {
          id: "ru-row",
          units: 426
        },

        {
          id: "kz-row",
          units: 61
        },

        {
          id: "ro-row",
          units: 5
        },

        {
          id: "by-row",
          units: 20
        },
        {
          id: "lt-row",
          units: 7
        },

        {
          id: "ee-row",
          units: 8
        },
        {
          id: "uk-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map"
    },

    q2: {
      countries: [
        {
          id: "ru-row",
          units: 458
        },

        {
          id: "kz-row",
          units: 66
        },

        {
          id: "ro-row",
          units: 5
        },

        {
          id: "by-row",
          units: 32
        },
        {
          id: "lt-row",
          units: 8
        },

        {
          id: "ee-row",
          units: 9
        },
        {
          id: "uk-row",
          units: 2
        },

        {
          id: "cn-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map"
    },

    q3: {
      countries: [
        {
          id: "ru-row",
          units: 476
        },

        {
          id: "kz-row",
          units: 69
        },

        {
          id: "ro-row",
          units: 5
        },

        {
          id: "by-row",
          units: 37
        },
        {
          id: "lt-row",
          units: 9
        },

        {
          id: "ee-row",
          units: 9
        },
        {
          id: "uk-row",
          units: 3
        },

        {
          id: "cn-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map"
    },

    q4: {
      countries: [
        {
          id: "ru-row",
          units: 501
        },

        {
          id: "kz-row",
          units: 72
        },

        {
          id: "ro-row",
          units: 5
        },

        {
          id: "by-row",
          units: 37
        },
        {
          id: "lt-row",
          units: 10
        },

        {
          id: "ee-row",
          units: 9
        },
        {
          id: "uk-row",
          units: 3
        },

        {
          id: "cn-row",
          units: 2
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map"
    }
  },

  2019: {
    q1: {
      countries: [
        {
          id: "ru-row",
          units: 521
        },

        {
          id: "kz-row",
          units: 74
        },

        {
          id: "ro-row",
          units: 6
        },

        {
          id: "by-row",
          units: 39
        },
        {
          id: "lt-row",
          units: 10
        },

        {
          id: "ee-row",
          units: 9
        },
        {
          id: "uk-row",
          units: 3
        },

        {
          id: "cn-row",
          units: 2
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map"
    },

    q2: {
      countries: [
        {
          id: "ru-row",
          units: 533
        },

        {
          id: "kz-row",
          units: 77
        },

        {
          id: "ro-row",
          units: 6
        },

        {
          id: "by-row",
          units: 39
        },
        {
          id: "lt-row",
          units: 11
        },

        {
          id: "ee-row",
          units: 9
        },
        {
          id: "uk-row",
          units: 4
        },

        {
          id: "cn-row",
          units: 2
        },
        {
          id: "si-row",
          units: 1
        },

        {
          id: "ng-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map, #si-map, #ng-map"
    },

    q3: {
      countries: [
        {
          id: "ru-row",
          units: 567
        },

        {
          id: "kz-row",
          units: 79
        },

        {
          id: "ro-row",
          units: 6
        },

        {
          id: "by-row",
          units: 39
        },
        {
          id: "lt-row",
          units: 11
        },

        {
          id: "ee-row",
          units: 10
        },
        {
          id: "uk-row",
          units: 5
        },

        {
          id: "cn-row",
          units: 2
        },
        {
          id: "si-row",
          units: 1
        },

        {
          id: "ng-row",
          units: 1
        },

        {
          id: "de-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map, #si-map, #ng-map, #de-map"
    },

    q4: {
      countries: [
        {
          id: "ru-row",
          units: 602
        },

        {
          id: "kz-row",
          units: 81
        },

        {
          id: "ro-row",
          units: 6
        },

        {
          id: "by-row",
          units: 42
        },
        {
          id: "lt-row",
          units: 11
        },

        {
          id: "ee-row",
          units: 10
        },
        {
          id: "uk-row",
          units: 5
        },

        {
          id: "cn-row",
          units: 2
        },
        {
          id: "si-row",
          units: 1
        },

        {
          id: "ng-row",
          units: 1
        },

        {
          id: "de-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map, #si-map, #ng-map, #de-map"
    }
  },

  2020: {
    q1: {
      countries: [
        {
          id: "ru-row",
          units: 611
        },

        {
          id: "kz-row",
          units: 82
        },

        {
          id: "ro-row",
          units: 6
        },

        {
          id: "by-row",
          units: 48
        },
        {
          id: "lt-row",
          units: 12
        },

        {
          id: "ee-row",
          units: 10
        },
        {
          id: "uk-row",
          units: 5
        },

        {
          id: "cn-row",
          units: 2
        },
        {
          id: "si-row",
          units: 2
        },

        {
          id: "ng-row",
          units: 1
        },

        {
          id: "de-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map, #si-map, #ng-map, #de-map"
    },

    q2: {
      countries: [
        {
          id: "ru-row",
          units: 632
        },

        {
          id: "kz-row",
          units: 82
        },

        {
          id: "ro-row",
          units: 6
        },

        {
          id: "by-row",
          units: 48
        },
        {
          id: "lt-row",
          units: 12
        },

        {
          id: "ee-row",
          units: 10
        },
        {
          id: "uk-row",
          units: 5
        },

        {
          id: "cn-row",
          units: 2
        },
        {
          id: "si-row",
          units: 3
        },

        {
          id: "ng-row",
          units: 3
        },

        {
          id: "de-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map, #si-map, #ng-map, #de-map"
    },

    q3: {
      countries: [
        {
          id: "ru-row",
          units: 676
        },

        {
          id: "kz-row",
          units: 93
        },

        {
          id: "ro-row",
          units: 8
        },

        {
          id: "by-row",
          units: 48
        },
        {
          id: "lt-row",
          units: 14
        },

        {
          id: "ee-row",
          units: 10
        },
        {
          id: "uk-row",
          units: 5
        },

        {
          id: "cn-row",
          units: 2
        },
        {
          id: "si-row",
          units: 3
        },

        {
          id: "ng-row",
          units: 4
        },

        {
          id: "de-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map, #si-map, #ng-map, #de-map"
    },

    q4: {
      countries: [
        {
          id: "ru-row",
          units: 684
        },

        {
          id: "kz-row",
          units: 93
        },

        {
          id: "ro-row",
          units: 8
        },

        {
          id: "by-row",
          units: 48
        },
        {
          id: "lt-row",
          units: 14
        },

        {
          id: "ee-row",
          units: 11
        },
        {
          id: "uk-row",
          units: 5
        },

        {
          id: "cn-row",
          units: 2
        },
        {
          id: "si-row",
          units: 3
        },

        {
          id: "ng-row",
          units: 6
        },

        {
          id: "de-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map, #si-map, #ng-map, #de-map"
    },

    totalCountries: 11,
    totalUnits: 721
  },

  2021: {
    q1: {
      countries: [
        {
          id: "ru-row",
          units: 712
        },

        {
          id: "kz-row",
          units: 93
        },

        {
          id: "ro-row",
          units: 9
        },

        {
          id: "by-row",
          units: 48
        },
        {
          id: "lt-row",
          units: 14
        },

        {
          id: "ee-row",
          units: 12
        },
        {
          id: "uk-row",
          units: 5
        },

        {
          id: "cn-row",
          units: 2
        },
        {
          id: "si-row",
          units: 4
        },

        {
          id: "ng-row",
          units: 6
        },

        {
          id: "de-row",
          units: 1
        },
        {
          id: "pl-row",
          units: 1
        },

        {
          id: "vn-row",
          units: 1
        },

        {
          id: "tj-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map, #si-map, #ng-map, #de-map, #pl-map, #vn-map, #tj-map"
    },

    q2: {
      countries: [
        {
          id: "ru-row",
          units: 738
        },

        {
          id: "kz-row",
          units: 96
        },

        {
          id: "ro-row",
          units: 10
        },

        {
          id: "by-row",
          units: 48
        },
        {
          id: "lt-row",
          units: 14
        },

        {
          id: "ee-row",
          units: 14
        },
        {
          id: "uk-row",
          units: 5
        },

        {
          id: "cn-row",
          units: 3
        },
        {
          id: "si-row",
          units: 5
        },

        {
          id: "ng-row",
          units: 6
        },

        {
          id: "de-row",
          units: 1
        },
        {
          id: "pl-row",
          units: 1
        },

        {
          id: "vn-row",
          units: 2
        },

        {
          id: "tj-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map, #si-map, #ng-map, #de-map, #pl-map, #vn-map, #tj-map"
    },

    q3: {
      countries: [
        {
          id: "ru-row",
          units: 742
        },

        {
          id: "kz-row",
          units: 96
        },

        {
          id: "ro-row",
          units: 10
        },

        {
          id: "by-row",
          units: 54
        },
        {
          id: "lt-row",
          units: 14
        },

        {
          id: "ee-row",
          units: 14
        },
        {
          id: "uk-row",
          units: 5
        },

        {
          id: "cn-row",
          units: 3
        },
        {
          id: "si-row",
          units: 5
        },

        {
          id: "ng-row",
          units: 6
        },

        {
          id: "de-row",
          units: 1
        },
        {
          id: "pl-row",
          units: 1
        },

        {
          id: "vn-row",
          units: 2
        },

        {
          id: "tj-row",
          units: 3
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map, #si-map, #ng-map, #de-map, #pl-map, #vn-map, #tj-map"
    },

    q4: {
      countries: [
        {
          id: "ru-row",
          units: 789
        },

        {
          id: "kz-row",
          units: 100
        },

        {
          id: "ro-row",
          units: 10
        },

        {
          id: "by-row",
          units: 54
        },
        {
          id: "lt-row",
          units: 14
        },

        {
          id: "ee-row",
          units: 14
        },
        {
          id: "uk-row",
          units: 5
        },

        {
          id: "cn-row",
          units: 3
        },
        {
          id: "si-row",
          units: 5
        },

        {
          id: "ng-row",
          units: 6
        },

        {
          id: "de-row",
          units: 1
        },
        {
          id: "pl-row",
          units: 1
        },

        {
          id: "vn-row",
          units: 2
        },

        {
          id: "tj-row",
          units: 5
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map, #si-map, #ng-map, #de-map, #pl-map, #vn-map, #tj-map"
    }
  },

  2022: {
    q1: {
      countries: [
        {
          id: "ru-row",
          units: 710
        },

        {
          id: "kz-row",
          units: 100
        },

        {
          id: "ro-row",
          units: 10
        },

        {
          id: "by-row",
          units: 54
        },
        {
          id: "lt-row",
          units: 14
        },

        {
          id: "ee-row",
          units: 14
        },
        {
          id: "uk-row",
          units: 5
        },

        {
          id: "cn-row",
          units: 3
        },
        {
          id: "si-row",
          units: 5
        },

        {
          id: "ng-row",
          units: 6
        },

        {
          id: "de-row",
          units: 1
        },
        {
          id: "pl-row",
          units: 2
        },

        {
          id: "vn-row",
          units: 3
        },

        {
          id: "tj-row",
          units: 5
        },
        {
          id: "kg-row",
          units: 4
        },

        {
          id: "kz-row",
          units: 1
        },

        {
          id: "uz-row",
          units: 1
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map, #si-map, #ng-map, #de-map, #pl-map, #vn-map, #tj-map, #kg-map, #uz-map"
    },

    q2: {
      countries: [
        {
          id: "ru-row",
          units: 725
        },

        {
          id: "kz-row",
          units: 100
        },

        {
          id: "ro-row",
          units: 10
        },

        {
          id: "by-row",
          units: 54
        },
        {
          id: "lt-row",
          units: 14
        },

        {
          id: "ee-row",
          units: 14
        },
        {
          id: "uk-row",
          units: 5
        },

        {
          id: "cn-row",
          units: 3
        },
        {
          id: "si-row",
          units: 5
        },

        {
          id: "ng-row",
          units: 6
        },

        {
          id: "de-row",
          units: 1
        },
        {
          id: "pl-row",
          units: 3
        },

        {
          id: "vn-row",
          units: 5
        },

        {
          id: "tj-row",
          units: 5
        },
        {
          id: "kg-row",
          units: 5
        },

        {
          id: "kz-row",
          units: 3
        },

        {
          id: "uz-row",
          units: 2
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map, #si-map, #ng-map, #de-map, #pl-map, #vn-map, #tj-map, #kg-map, #uz-map"
    },

    q3: {
      countries: [
        {
          id: "ru-row",
          units: 740
        },

        {
          id: "kz-row",
          units: 100
        },

        {
          id: "ro-row",
          units: 10
        },

        {
          id: "by-row",
          units: 54
        },
        {
          id: "lt-row",
          units: 14
        },

        {
          id: "ee-row",
          units: 14
        },
        {
          id: "uk-row",
          units: 5
        },

        {
          id: "cn-row",
          units: 3
        },
        {
          id: "si-row",
          units: 5
        },

        {
          id: "ng-row",
          units: 6
        },

        {
          id: "de-row",
          units: 1
        },
        {
          id: "pl-row",
          units: 3
        },

        {
          id: "vn-row",
          units: 6
        },

        {
          id: "tj-row",
          units: 5
        },
        {
          id: "kg-row",
          units: 5
        },

        {
          id: "kz-row",
          units: 4
        },

        {
          id: "uz-row",
          units: 3
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map, #si-map, #ng-map, #de-map, #pl-map, #vn-map, #tj-map, #kg-map, #uz-map"
    },

    q4: {
      countries: [
        {
          id: "ru-row",
          units: 735
        },

        {
          id: "kz-row",
          units: 111
        },

        {
          id: "ro-row",
          units: 10
        },

        {
          id: "by-row",
          units: 54
        },
        {
          id: "lt-row",
          units: 14
        },

        {
          id: "ee-row",
          units: 14
        },
        {
          id: "uk-row",
          units: 5
        },

        {
          id: "cn-row",
          units: 3
        },
        {
          id: "si-row",
          units: 5
        },

        {
          id: "ng-row",
          units: 6
        },

        {
          id: "de-row",
          units: 1
        },
        {
          id: "pl-row",
          units: 3
        },

        {
          id: "vn-row",
          units: 6
        },

        {
          id: "tj-row",
          units: 5
        },
        {
          id: "kg-row",
          units: 7
        },

        {
          id: "kz-row",
          units: 8
        },

        {
          id: "uz-row",
          units: 4
        }
      ],
      mapUnits:
        "#KO, #ARK, #KIR, #KOS, #SVE, #KYA, #TVE, #MOS, #NGR, #RYA, #PNZ, #VLG, #IVA, #YAR, #UD, #ME, #NIZ, #TA, #TUL, #VOR, #KRS, #ORL, #TAM, #LIP, #VGG, #IRK, #YAN, #KHA, #NVS, #TOM, #BU, #BA, ROS, #STA, #AST, #DA, #AL, #YEV,#ZAB, #CHU, #SAM, #KR, #KLU, #PSK, #MOW, #VLA, #CU, #MO, #SMO, #KHM, #SA, #MAG, #MUR, #NEN, #TYU, #KGD, #LEN, #BRY, #SAR, #BEL, #ULU, #OMS, #ORE, #KGN, #CHE, #KL, #KDA, #KC, #KB, #CE, #SE, #IN, #SPE, #KEM, #AMU, #TY, #ALT, #KK, #PRI, #SAK, #KAM, #PER, #ULY, #kz-map, #ro-map, #by-map, #lt-map, #ee-map, #uk-map, #cn-map, #si-map, #ng-map, #de-map, #pl-map, #vn-map, #tj-map, #kg-map, #uz-map"
    }
  }
};

$(document).ready(function() {
  // All functions

  function loadDefaultView() {
    countrySelector("2022", "q4");

    $(".bar-container#y2022").addClass("active");

    $(".quoter").each(function() {
      $(this).removeClass("active");
    });
  }

  // Clearing map

  function clearMap() {
    $(".country-map").each(function() {
      $(this).removeClass("active");
    });
  }

  // Choosing country and updating counters

  function countrySelector(yearID, quoter) {
    $(".bar-container").each(function() {
      $(this).removeClass("active");
    });

    $(".revenue-data-item").each(function() {
      $(this).removeClass("active");
    });

    $(".quoter").each(function() {
      $(this).removeClass("active");
    });

    $(`#${yearID}-${quoter}`).addClass("active");

    $(".revenue-data-item").each(function() {
      const itemId = $(this).attr("id");

      const country = state[yearID][quoter].countries.find(function(item) {
        return item.id === itemId;
      });

      if (country !== undefined) {
        $(this).addClass("active");

        $(this)
          .children()
          .children("#units")
          .html(country.units);
      }
    });

    const unitCounter = state[yearID][quoter].countries.reduce(function(
      acc,
      currentItem
    ) {
      return acc + currentItem.units;
    },
    0);

    if (document.getElementById("total-countries")) {
      document.getElementById("total-countries").innerHTML =
        state[yearID][quoter].countries.length;
      document.getElementById("total-units").innerHTML = unitCounter;

      clearMap();

      $(state[yearID][quoter].mapUnits).addClass("active");
    }
  }

  //  Making all look default

  loadDefaultView();

  //  Separating by quoters

  function quoteYearUpdate(qYear) {
    // Cycle to go trow quoters

    const quotersValues = ["q1", "q2", "q3", "q4"];
    quotersValues.forEach(function(item) {
      // .on('mouseenter touchstart', '.hover .image-wrapper', e => {
      //   $(e.currentTarget)
      //     .siblings('.image-indicator')
      //     .fadeIn(10);
      // })

      $(`#${qYear}-${item}`).on("mouseenter touchmove", function() {
        countrySelector(qYear, item);
      });

      // $(`#${qYear}-${item}`).touchStart(function() {
      //   countrySelector(qYear, item);
      // });

      $(".quoter").each(function() {
        $(this).removeClass("active");
      });
    });

    // $(`#${qYear}-q2`).mouseenter(function() {
    //   countrySelector(qYear, "q2");
    // });

    // $(`#${qYear}-q3`).mouseenter(function() {
    //   countrySelector(qYear, "q3");
    // });

    // $(`#${qYear}-q4`).mouseenter(function() {
    //   countrySelector(qYear, "q4");
    // });
  }

  //  Calling each year and quoter

  quoteYearUpdate("2011");
  quoteYearUpdate("2012");
  quoteYearUpdate("2013");
  quoteYearUpdate("2014");
  quoteYearUpdate("2015");
  quoteYearUpdate("2016");
  quoteYearUpdate("2017");
  quoteYearUpdate("2018");
  quoteYearUpdate("2019");
  quoteYearUpdate("2020");
  quoteYearUpdate("2021");
  quoteYearUpdate("2022");

  // Removing all active bars on mouse leave

  $(".revenue-diagram-container").on("mouseleave touchend", function() {
    loadDefaultView();
  });
});
