import $ from "jquery";

import "../styles/main.scss";
import "./awakeness";
import "./summary";
import "./scroll-link";
import "./sales";
import "./dodois-table";
import "./newspaper";
import "./floating-things";

import "./real-time-date";

import "./is-scheme";
import "./vimeo-scaler";

import { yall } from "yall-js";
yall();

import "./pizza-names";
