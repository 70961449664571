import $, { each } from "jquery";

let checkpoints = [0, 3.1, 6.65, 10.1, 14];

$(document).ready(function() {
  let video = document.querySelector("#pizzas");

  if (video) {
    setInterval(() => {
      let time = video.currentTime;

      let currentIndex = checkpoints.findIndex(e => e > time) - 1;

      let boxes = document.querySelectorAll(".pizza-name-box");

      boxes.forEach((element, index) => {
        element.classList.remove("active");
        if (currentIndex === index) {
          element.classList.add("active");
        }
      });
    }, 100);
  }
});
