import $, { each, easing } from "jquery";


var coll = document.getElementsByClassName("feature");
var i;

for (i = 0; i < coll.length; i++) {
  coll[i].addEventListener("click", function(e) {

    
    const fullDetails = e.target.querySelector(".full-details");
    
    if (fullDetails) {
      this.classList.toggle("open");
      
      if (fullDetails.style.display === "block") {
        fullDetails.style.display = "none";
  
      } else {
        fullDetails.style.display = "block";
      }
    }
    

  });
} 