import $, { each } from "jquery";

const ready = function() {
  let timeouts = [];
  let metronome;

  function resetIs() {
    $(".box").each(function() {
      $(this).removeClass("active");
    });

    $(".line").each(function() {
      $(this).removeClass("active");
    });

    $(".screen-image").each(function() {
      $(this).removeClass("active");
    });
  }

  $(".label").hover(function() {
    resetIs();
    timeouts.map(clearTimeout);
    if (metronome) {
      clearInterval(metronome);
    }
    $(".data-platform-container").addClass("pulsating");

    const label = $(this).data("label");
    activateLabel(label);
  });

  $(".label").on("mouseleave touchend", function() {
    resetIs();

    activateSequense();
    metronome = setInterval(() => activateSequense(), 7000);

    $(".screen-image.data-platform").addClass("active");
    $(".data-platform-container").removeClass("pulsating");
  });

  function activateLabel(label) {
    resetIs();

    $(".box." + label).addClass("active");
    $(".line." + label).addClass("active");
    $(".screen-image." + label).addClass("active");
  }

  function activateSequense() {
    timeouts = [];

    timeouts.push(
      setTimeout(function() {
        $(".data-platform-container").addClass("pulsating");
      }, 1000)
    );
    timeouts.push(setTimeout(() => activateLabel("apps"), 1000));
    timeouts.push(setTimeout(() => activateLabel("payments"), 1100));
    timeouts.push(setTimeout(() => activateLabel("production"), 1200));
    timeouts.push(setTimeout(() => activateLabel("delivery"), 1300));

    timeouts.push(setTimeout(() => activateLabel("apps"), 1800));
    timeouts.push(setTimeout(() => activateLabel("payments"), 1900));
    timeouts.push(setTimeout(() => activateLabel("production"), 2000));
    timeouts.push(setTimeout(() => activateLabel("delivery"), 2100));

    timeouts.push(setTimeout(() => activateLabel("apps"), 2600));
    timeouts.push(setTimeout(() => activateLabel("payments"), 2700));
    timeouts.push(setTimeout(() => activateLabel("production"), 2800));
    timeouts.push(setTimeout(() => activateLabel("delivery"), 2900));

    timeouts.push(setTimeout(() => activateLabel("procurement"), 3500));
    timeouts.push(setTimeout(() => activateLabel("marketing"), 4000));
    timeouts.push(setTimeout(() => activateLabel("menu"), 4500));
    timeouts.push(setTimeout(() => activateLabel("security"), 5000));
    timeouts.push(setTimeout(() => activateLabel("quality"), 5500));

    timeouts.push(setTimeout(() => activateLabel("management"), 6000));
    timeouts.push(setTimeout(() => activateLabel("pl"), 6500));
  }

  activateSequense();
  metronome = setInterval(() => activateSequense(), 7000);
};

$(document).ready(ready);
