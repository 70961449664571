import $, { each } from "jquery";

const ready = function() {
  let time = function() {
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec"
    ];

    let mon = new Date();
    let d = new Date();
    let h = new Date();
    let m = new Date();

    function addZeroes(i) {
      // Add zero in front of numbers if needed (< 10)
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }

    let monthName = month[mon.getMonth()];
    let day = d.getDate();
    let hour = h.getHours();
    let minutes = m.getMinutes();

    hour = addZeroes(hour);
    minutes = addZeroes(minutes);

    document.getElementById("monthName").innerHTML = monthName;
    document.getElementById("day").innerHTML = day;
    document.getElementById("hour").innerHTML = hour;
    document.getElementById("minutes").innerHTML = minutes;
  };

  setInterval(time, 1000);
};

$(document).ready(ready);
