import $, { each } from "jquery";

const ready = function() {
  if ($(".vimeo-player-box").length) {
    window.addEventListener("resize", scaleVimeo);
    scaleVimeo();
  }
};

const scaleVimeo = () => {
  var players = document.getElementsByClassName("video-box");

  for (let playerDOM of players) {
    const vimeoBox = playerDOM.getElementsByClassName("vimeo-player-box");
    const vimeoBoxWidth = vimeoBox[0].offsetWidth;
    const vimeoAspectRatio = vimeoBox[0].getAttribute("data-aspect-ratio");

    vimeoBox[0].setAttribute(
      "style",
      `height:${vimeoBoxWidth / vimeoAspectRatio}px`
    );

    const iframe = playerDOM.querySelector("iframe");
    const player = new Vimeo.Player(iframe);

    const playButton = playerDOM.getElementsByClassName("play-button")[0];
    const readButton = playerDOM.getElementsByClassName("read-button")[0];
    const videoPreview = playerDOM.getElementsByClassName("video-preview")[0];
    const playButtonLink = playButton.querySelector("a");

    videoPreview.onclick = function(e) {
      e.preventDefault();
      loadAndPlayVideo();

      $("html, body").animate(
        {
          scrollTop: $("#video-box").offset().top - 19
        },
        500
      );
    };

    videoPreview.onmouseenter = function(e) {
      playButtonLink.classList.add("hover");
    };
    videoPreview.onmouseleave = function(e) {
      playButtonLink.classList.remove("hover");
    };

    playButton.onclick = function(e) {
      e.preventDefault();
      loadAndPlayVideo();
    };

    function loadAndPlayVideo() {
      playButton.classList.add("loading");
      player.play();
    }

    player.on("play", e => {
      // console.log(123);
      playButton.setAttribute("style", `opacity: 0; pointer-events: none;`);
      readButton.setAttribute("style", `opacity: 0; pointer-events: none;`);
      videoPreview.setAttribute("style", `opacity: 0; pointer-events: none;`);
    });
  }
};

$(document).ready(ready);
